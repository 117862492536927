import React, { useState, useEffect } from "react";
import { 
  Box, 
  Button, 
  CircularProgress, 
  MenuItem, 
  Select, 
  Typography, 
  LinearProgress, 
  IconButton, 
  TextField,
  Collapse
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import SendToMobileOutlinedIcon from '@mui/icons-material/SendToMobileOutlined';
import styled from "styled-components";
import axios from "axios";
import { API_PREFIX } from "../../constants/urls";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const ImageForwardingButton = styled(Button)`
  display: block;
  width: 100%;
  height: 28px;
  line-height: 30px;
  @media all and (max-width: 799px){
    width: 100%;
  }
`;

const ImageGrid = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: center;
  margin-top: 10px;
`;

const ImageWrapper = styled(Box)<{ selected: boolean }>`
  position: relative;
  width: 80px;
  height: 80px;
  border-radius: 6px;
  overflow: hidden;
  border: ${({ selected }) => (selected ? "2px solid #007FFF" : "2px solid #ddd")};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
`;

const ImageThumb = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const RemoveButton = styled(IconButton)`
  position: absolute;
  top: 2px;
  right: 2px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  width: 20px;
  height: 20px;
`;

interface ImageForwardingProps {
  leadId: string;
  images: string[];
}

const HARDCODED_NUMBERS = [
  { label: "Samm (CBO)", number: "+61417754177" },
  { label: "Philip (CBO)", number: "+61418440044" },
  { label: "Wade (CBO)", number: "+61408741493" },
//  { label: "Mark (CBO)", number: "+61431519078" },
];

const ImageForwarding: React.FC<ImageForwardingProps> = ({ leadId, images }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedNumber, setSelectedNumber] = useState(HARDCODED_NUMBERS[0].number);
  const [dealerContacts, setDealerContacts] = useState<{ id: string; label: string; number: string }[]>([]);
  const [isSending, setIsSending] = useState(false);
  const [sentCount, setSentCount] = useState(0);
  const [sendStatus, setSendStatus] = useState<string | null>(null);
  const [selectedImages, setSelectedImages] = useState<string[]>([]);
  const [newContactName, setNewContactName] = useState("");
  const [newContactNumber, setNewContactNumber] = useState("");
  const [isAddingContact, setIsAddingContact] = useState(false);

  useEffect(() => {
    setSelectedImages(images.slice(0, 5)); // Default to selecting the first 5 images
  }, [images]);

  const toggleImageSelection = (imageUrl: string) => {
    setSelectedImages((prevSelected) => {
      if (prevSelected.includes(imageUrl)) {
        return prevSelected.filter((img) => img !== imageUrl);
      } else {
        return [...prevSelected, imageUrl];
      }
    });
  };

  // Fetch dealer contacts from the server when the dialog opens
  useEffect(() => {
    if (dialogOpen) {
      fetchDealerContacts();
    }
  }, [dialogOpen]);

  
  const fetchDealerContacts = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${API_PREFIX}/dealer-contacts`, 
        {
        headers: { Authorization: `Bearer ${token}` },
        });
      setDealerContacts(response.data);
    } catch (error) {
      console.error("Error fetching dealer contacts:", error);
    }
  };

  const handleAddContact = async () => {
    if (!newContactName.trim() || !newContactNumber.trim()) return;

    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(`${API_PREFIX}/dealer-contacts`, 
        {
        label: newContactName.trim(),
        number: newContactNumber.trim(),
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      });
      setDealerContacts([...dealerContacts, response.data]);
      setNewContactName("");
      setNewContactNumber("");
      setIsAddingContact(false);
    } catch (error) {
      console.error("Error adding contact:", error);
    }
  };

  const handleDeleteContact = async (id: string) => {
    try {
      const token = localStorage.getItem("token");
      const contactToDelete = dealerContacts.find(contact => contact.id === id);
      await axios.delete(`${API_PREFIX}/dealer-contacts/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setDealerContacts(dealerContacts.filter((contact) => contact.id !== id));
      if (contactToDelete && contactToDelete.number === selectedNumber) {
        setSelectedNumber("");
      }
    } catch (error) {
      console.error("Error deleting contact:", error);
    }
  };

  const handleSendImages = async () => {
    if (selectedImages.length === 0) {
      setSendStatus("error");
      return;
    }

    setIsSending(true);
    setSentCount(0);
    setSendStatus(null);
    setDialogOpen(false);

    try {
      const token = localStorage.getItem("token");

      for (let i = 0; i < selectedImages.length; i++) {
        let success = false;
        let attempt = 0;

        const bodyText = `Image ${i + 1} / ${selectedImages.length}`;

        while (!success && attempt < 2) {
          try {
            console.log(`Sending image ${i + 1}/${selectedImages.length}... Attempt ${attempt + 1}`);

            const response = await axios.post(
              `${API_PREFIX}/send-photos-to-number`,
              {
                leadId,
                to: selectedNumber,
                imageUrl: selectedImages[i],
                currentIndex: i + 1,
                totalImages: selectedImages.length,
                bodyText,
              },
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            );

            console.log(`Image ${i + 1} sent successfully:`, response.data);
            success = true;
          } catch (error) {
            console.error(`Error sending image ${i + 1}:`, error);
            attempt++;
          }
        }

        setSentCount((prev) => prev + 1); // Update progress after success
      }

      setSendStatus("success");
    } catch (error) {
      console.error("Error sending MMS:", error);
      setSendStatus("error");
    } finally {
      setIsSending(false);
    }
  };

  return (
    <Box>
      <Box style={{ marginTop: "8px", marginBottom: "10px" }}>
        <ImageForwardingButton variant="outlined" color="primary" onClick={() => setDialogOpen(true)}>
          Forward Images <SendToMobileOutlinedIcon sx={{ width: '16px', marginLeft: '6px'  }} />
        </ImageForwardingButton>
      </Box>

      {/* Confirmation Dialog */}
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Select Images to Forward</DialogTitle>
        <DialogContent sx={{ maxWidth: '460px' }}>
          <ImageGrid>
            {images.map((image) => (
              <ImageWrapper
                key={image}
                selected={selectedImages.includes(image)}
                onClick={() => toggleImageSelection(image)}
              >
                <ImageThumb src={image} alt="Preview" />
                {selectedImages.includes(image) && (
                  <RemoveButton onClick={(e) => { e.stopPropagation(); toggleImageSelection(image); }} sx={{ position: 'absolute', }}>
                    <CloseIcon fontSize="small" />
                  </RemoveButton>
                )}
              </ImageWrapper>
            ))}
          </ImageGrid>

          <Typography variant="body2" sx={{ mt: 2 }}>
            Send the selected {selectedImages.length} image{selectedImages.length === 1 ? "" : "s"} to:
          </Typography>

          <Select
            fullWidth
            value={selectedNumber}
            onChange={(e) => setSelectedNumber(e.target.value as string)}
            sx={{ mt: 2, lineHeight: "30px" }}
          >
            {HARDCODED_NUMBERS.map(({ label, number }) => (
              <MenuItem key={number} value={number}>
                {label} ({number})
              </MenuItem>
            ))}
            {dealerContacts.map(({ id, label, number }) => (
              <MenuItem key={id} value={number} sx={{ width: '100%', justifyContent: 'space-between' }}>
                {label} ({number})
                {selectedNumber !== number && (
                  <IconButton onClick={() => handleDeleteContact(id)} size="small" style={{ fontSize: '12px', color: '#CCC' }}>
                    Delete <CloseIcon fontSize="small" style={{ color: '#CCC' }} />
                  </IconButton>
                )}
              </MenuItem>
            ))}
          </Select>

          {!isAddingContact && (
            <Typography
              variant="body2"
              sx={{ mt: 1, color: "#B9E123", cursor: "pointer", textDecoration: "none" }}
              onClick={() => setIsAddingContact(!isAddingContact)}
            >
              Add Contact +
            </Typography>
          )}
          
          <Collapse in={isAddingContact}>
            <TextField fullWidth label="Name" value={newContactName} onChange={(e) => setNewContactName(e.target.value)} sx={{ mt: 2, input: { height: '25px', maxHeight: '25px'} }} />
            <TextField fullWidth label="Phone Number" value={newContactNumber} onChange={(e) => setNewContactNumber(e.target.value)} sx={{ mt: 2 }} />
            <Button onClick={() => setIsAddingContact(!isAddingContact)} sx={{ margin: '10px 10px 0 10px' }} color="secondary">Cancel</Button>
            <Button onClick={handleAddContact} sx={{ margin: '10px 0 0 0' }}>Save Contact</Button>
          </Collapse>
          
        </DialogContent>
        <DialogActions sx={{ mb: 1 }}>
          <Button color="secondary" onClick={() => setDialogOpen(false)}>
            Cancel
          </Button>
          <Button color="primary" onClick={handleSendImages} disabled={selectedImages.length === 0}>
            Send {selectedImages.length} Image{selectedImages.length > 1 ? "s" : ""}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Sending Progress Indicator */}
      {isSending && (
        <Box mt={2} sx={{ width: "100%" }}>
          <LinearProgress variant="determinate" value={(sentCount / selectedImages.length) * 100} />
          <Typography variant="body2" color="textSecondary" sx={{ mt: 1, textAlign: "center" }}>
            Sending {sentCount}/{selectedImages.length}...
          </Typography>
        </Box>
      )}

      {/* Success or Error Message */}
      {sendStatus === "success" && (
        <Typography variant="body2" sx={{ mt: 1, mb: 1, textAlign: "center", color: "#B9E123" }}>
          Images sent successfully!
        </Typography>
      )}
      {sendStatus === "error" && (
        <Typography variant="body2" sx={{ mt: 1, mb: 1, textAlign: "center", color: "#F22020" }}>
          Failed to send images. Please try again.
        </Typography>
      )}
    </Box>
  );
};

export default ImageForwarding;
