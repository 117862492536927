import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import styled from 'styled-components';
import HideImageIcon from '@mui/icons-material/HideImage';
import Lightbox from './LightBox';
import ImageForwarding from "./ImageForwarding";

const ImageContainer = styled(Box)`
  position: relative;
  display: grid;
  gap: 6px;
  width: 272px;
  height: auto; // inline-style based on image count
  max-width: 100%;
  overflow: hidden;
  @media all and (max-width: 1099px){
    width: 192px;
  }
    @media all and (max-width: 899px){
    width: 112px;
  }
  @media all and (max-width: 799px){
    width: 100%;
  }
`
const Image = styled(Box)`
  cursor: pointer;
`
const OverlayText = styled(Typography)`
&&{  
  position: absolute;
  background: rgba(0,0,0,0.6);
  height: 16px;
  font-size: 12px;
  line-height: 16px;
  border-radius: 10px;
  padding: 3px 8px 2px;
  color: white;
  bottom: 4px;
  left: 4px;
  z-index: 3;
}
`
const OverlayTextAlt = styled(Typography)`
&&{  
  position: absolute;
  display: flex;
  justify-content: start;
  color: #777;
  height: 16px;
  font-size: 12px;
  line-height: 16px;
  border: 0 none;
  border-radius: 10px;
  padding: 4px 6px 2px;
  top: 50%;
}
`

interface ImageDisplayProps {
  leadId: string;
  images: string[];
  isMobile: boolean;
  isTablet: boolean;
}

const ImageDisplay: React.FC<ImageDisplayProps> = ({ leadId, images, isMobile, isTablet }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentImages, setCurrentImages] = useState(images);
  const [initialIndex, setInitialIndex] = useState(0);

  // This function updates the currentImages state to reflect deletions
  const handleImageDelete = (deletedImageUrl) => {
    console.log("Attempting to delete image:", deletedImageUrl);
    const updatedImages = currentImages.filter(image => {
      console.log(`Comparing:\n${image}\nwith\n${deletedImageUrl}`);
      return image.trim() !== deletedImageUrl.trim();
    });
    console.log("Updated Images:", updatedImages);
    setCurrentImages(updatedImages);
    if (updatedImages.length === 0) {
      setIsOpen(false); // Close the Lightbox if all images are deleted
    }
  };

  const openLightbox = (index: React.SetStateAction<number>) => {
    setInitialIndex(index);
    setIsOpen(true);
  };
  
  const currentImageCount = currentImages.length;

  useEffect(() => {
    setCurrentImages(images);
  }, [images]);

  // Adjusting gridTemplateColumns and gridTemplateRows based on image count
  const gridStyle = (() => {
    switch(currentImageCount) {
      case 1:
        return { gridTemplateColumns: '1fr', gridTemplateRows: '272px' }; // Full height for a single image
      case 2:
        return { gridTemplateColumns: '1fr', gridTemplateRows: isTablet ? '98px' : '133px' }; // Side by side, each half width
      case 3:
        case 3:
        return { gridTemplateColumns: '1fr 1fr', gridTemplateRows: isTablet ? '98px 98px' : '133px 133px' }; 
      case 4:
        return { gridTemplateColumns: '1fr 1fr', gridTemplateRows: isTablet ? '98px 98px' : '133px 133px' }; // 2x2 grid for 3 or 4 images
      default:
        return { gridTemplateColumns: '1fr 1fr', gridTemplateRows: isTablet ? '98px 98px' : '133px 133px' }; // Default case, similar to 4 images
    }
  })();

  return (
    <Box>
      <ImageContainer style={{ 
        height: (isMobile && currentImageCount === 0) ? '30px' : isTablet ? (currentImageCount > 2 ? '202px' : '202px') : (currentImageCount > 2 ? '272px' : '272px'),
        borderRadius: '10px',
        ...gridStyle }}>
        {currentImageCount > 0 && (
          currentImages.slice(0, 4).map((image, index) => (
          <React.Fragment key={`${leadId}-${index}`}>
            <Image key={`${leadId}-${index}`} style={{
              backgroundImage: `url(${encodeURI(image)})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              borderRadius: '10px',
              width: currentImageCount === 3 && index === 0 ? '204%' : '100%',
              height: '100%',
              zIndex: index === 0 ? 2 : 1,
            }} 
            onClick={() => openLightbox(index)}
            />
            {currentImageCount === 3 && index === 0 && <div key="image-spacer"></div> }
          </React.Fragment>
          ))
        )}
        {currentImageCount > 0 ? (
          <OverlayText>
            {currentImageCount} Photo{currentImageCount > 1 ? 's' : ''}
          </OverlayText>
        ) : (
          <OverlayTextAlt style={{ left: isMobile ? '4px' : '50%', transform: isMobile ? 'translate(0, -50%)' : 'translate(-50%, -50%)', minWidth: '72px', background: isMobile ? 'rgba(0,0,0,0.1)' : '#F6F6F6'}}>
            <HideImageIcon style={{ display: 'block', width: '14px', margin: '-6px 4px 0 0' }} /> No photos
          </OverlayTextAlt>
        )}
      </ImageContainer>
      {images.length > 0 && <ImageForwarding leadId={leadId} images={images} />}
      <Lightbox isOpen={isOpen} leadId={leadId} images={currentImages} initialIndex={initialIndex} onClose={() => setIsOpen(false)} onImageDelete={handleImageDelete} />
    </Box>
  );
};

export default ImageDisplay;