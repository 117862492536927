import React, { useState } from 'react';
import { MenuItem, Select, FormControl, InputLabel, SelectChangeEvent } from '@mui/material';
import { ADMIN_USERS } from '../../constants/adminUsers';
import axios from 'axios';
import styled from 'styled-components';
import { API_PREFIX } from '../../constants/urls';

interface AssignLeadSelectorProps {
  leadId: string;
  assignedTo?: string;
}

const MicroSelect = styled(Select)`
&&{
  display: inline-block;
  width: 80px;
  height: 18px;
  font-size: 11px;
  line-height: 11px;
  box-sizing: border-box;
  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid grey; /* Remove focus border */
  }
  &:hover .MuiOutlinedInput-notchedOutline {
    border: 2px solid #B9E123; /* Optional hover styling */
  }
}
`;

const MicroMenuItem = styled(MenuItem)`
&&{
  font-size: 11px;
}
`

const AssignLeadSelector: React.FC<AssignLeadSelectorProps> = ({ leadId, assignedTo }) => {
  const [currentAssigned, setCurrentAssigned] = useState(assignedTo || '');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleAdminChange = async (event: SelectChangeEvent<unknown>) => {
    const newAssignedUser = event.target.value as string; // Cast `unknown` to `string`
    setIsSubmitting(true);

    try {
      const token = localStorage.getItem('token');
      await axios.post(
        `${API_PREFIX}/assign-lead`,
        { leadId, userId: newAssignedUser },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setCurrentAssigned(newAssignedUser); // Update UI with new assignment
      console.log(`Lead successfully assigned to ${newAssignedUser}`);
    } catch (error) {
      console.error('Error assigning lead:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <FormControl style={{ margin: '-2px 0 0 4px'}}>
      <MicroSelect
        labelId={`assign-admin-label-${leadId}`}
        value={currentAssigned}
        onChange={handleAdminChange}
        disabled={isSubmitting}
      >
        <MicroMenuItem key="" value="0" sx={{ minHeight: '10px' }}>
          <i>None</i>
        </MicroMenuItem>
        {ADMIN_USERS.map((user) => (
          <MicroMenuItem key={user.id} value={user.id} sx={{ minHeight: '10px' }}>
            {user.name}
          </MicroMenuItem>
        ))}
      </MicroSelect>
    </FormControl>
  );
};

export default AssignLeadSelector;
