import React, { useState, useEffect } from 'react';
import { Box, IconButton, Menu, MenuItem, Typography, Badge, Button } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import axios from 'axios';
import { API_PREFIX } from '../../constants/urls';
import { StyledDashboardButton } from '../theme/DashboardStyles';
import { format, differenceInDays, isToday, isYesterday } from 'date-fns';

interface Notification {
  id: string;
  uid: string;
  notificationType: string;
  customerName: string;
  vehicleDetails: string;
  message: string;
  timeSinceEnquiry: string;
  imageUrl: string;
  createdAt: string;
  notifiedUsers?: string[];
}

interface NotificationsProps {
  onNotificationClick: (searchQuery: string) => void;
  userId: string | undefined;
  refreshTrigger: number;
}

export function formatEnquiryTime(createdAt: number) {
  const enquiryDate = new Date(createdAt);
  const today = new Date();
  const diffDays = differenceInDays(today, enquiryDate);

  if (isToday(enquiryDate)) return 'Today';
  if (isYesterday(enquiryDate)) return 'Yesterday';
  if (diffDays < 7) return `${diffDays} days ago`;

  return format(enquiryDate, 'd MMM, yyyy'); // Example: "12 Jan, 2024"
}

const Notifications: React.FC<NotificationsProps> = ({ userId, onNotificationClick, refreshTrigger }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [unreadCount, setUnreadCount] = useState<number>(0);

  useEffect(() => {
    const fetchNotifications = async () => {
      if (document.hidden) return; // Do not fetch if the tab is inactive
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_PREFIX}/notifications`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setNotifications(response.data);
  
        if (userId) {
          const unreadNotifications = response.data.filter(
            (notification: Notification) =>
              notification.notifiedUsers && !notification.notifiedUsers.includes(userId)
          );
          setUnreadCount(unreadNotifications.length);
        }
      } catch (error) {
        console.error('Failed to fetch notifications:', error);
      }
    };
  
    // Initial fetch when component mounts
    fetchNotifications();
  
    // Set up polling every 5 minutes
    let interval = setInterval(fetchNotifications, 300000);
  
    // Handle tab visibility changes
    const handleVisibilityChange = () => {
      if (!document.hidden) { // User switched back to the tab
        fetchNotifications(); // Fetch immediately
        interval = setInterval(fetchNotifications, 300000); // Restart polling
      } else {
        clearInterval(interval); // Stop polling if tab is inactive
      }
    };
  
    document.addEventListener("visibilitychange", handleVisibilityChange);
  
    return () => {
      clearInterval(interval); // Cleanup interval on unmount
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  
  }, [userId, refreshTrigger]);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleNotificationClick = async (notification: Notification) => {
    onNotificationClick(notification.uid);
    handleMenuClose();

    if (userId && notification.notifiedUsers && !notification.notifiedUsers.includes(userId)) {
      try {
        const token = localStorage.getItem('token');
        await axios.post(
          `${API_PREFIX}/notifications/mark-as-seen`,
          { leadId: notification.id, userId },
          { headers: { Authorization: `Bearer ${token}` } }
        );

        setNotifications((prevNotifications) =>
          prevNotifications.map((n) =>
            n.id === notification.id
              ? { ...n, notifiedUsers: [...(n.notifiedUsers || []), userId] }
              : n
          )
        );
        setUnreadCount(unreadCount - 1);
      } catch (error) {
        console.error('Failed to mark notification as seen:', error);
      }
    }
  };

  const handleClearAllNotifications = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.post(`${API_PREFIX}/notifications/clear-all`, { userId },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      setNotifications((prevNotifications) =>
        prevNotifications.map((n) => ({
          ...n,
          notifiedUsers: [...(n.notifiedUsers || []), userId!],
        }))
      );
      setUnreadCount(0);
    } catch (error) {
      console.error('Failed to clear notifications:', error);
    }
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <StyledDashboardButton color="inherit" onClick={handleMenuOpen} style={{ height: '58px', marginLeft: 'auto' }}>
        <Badge
          badgeContent={unreadCount}
          sx={{
            '& .MuiBadge-badge': {
              backgroundColor: '#B9E123', 
              color: 'white', 
              fontSize: '0.65rem', 
              lineHeight: '17px',
              fontWeight: 'bold', 
              height: '16px',
              width: '16px',
              minWidth: '16px'
            },
          }}
        >
          <NotificationsIcon style={{ fontSize: '22px' }} />
        </Badge>
      </StyledDashboardButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        sx={{
          margin: '5px 0 0',
          maxHeight: 480,
          width: ['340px', '380px'], // Set a fixed width or adjust as needed
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {notifications.length === 0 ? (
          <MenuItem disabled>No new notifications</MenuItem>
        ) : (
          <Box sx={{ position: 'relative', width: ['300px', '350px'] }}>
            <Box sx={{ maxHeight: '300px', overflowY: 'auto' }}>
              {notifications.map((notification) => (
                <MenuItem
                  key={`${notification.id}-${notification.notificationType}`}
                  onClick={() => handleNotificationClick(notification)}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '8px 8px 2px 12px',
                    backgroundColor:
                      userId &&
                      notification.notifiedUsers &&
                      notification.notifiedUsers.includes(userId)
                        ? '#FFF'
                        : '#F6F6F6',
                    borderBottom: '1px solid #E9E9E9',
                  }}
                >
                  <Box sx={{ flex: 1, minWidth: 0, marginRight: '8px' }}>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: '14px',
                        fontWeight: 'bold',
                        lineHeight: '1.6',
                        whiteSpace: 'normal',
                        wordBreak: 'break-word',
                      }}
                    >
                      {notification.message}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        whiteSpace: 'normal',
                        wordBreak: 'break-word',
                      }}
                    >
                      {notification.customerName} ({notification.vehicleDetails})
                    </Typography>
                    <Typography variant="body2" color="textSecondary" sx={{ fontSize: '12px', margin: '2px 0'}}>
                      {formatEnquiryTime(Number(notification.createdAt))} - First enquired {formatEnquiryTime(Number(notification.createdAt) - Number(notification.timeSinceEnquiry))}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: '54px',
                      height: '54px',
                      flexShrink: 0,
                      marginLeft: '8px',
                    }}
                  >
                    {notification.notificationType === 'Appointment' ? (
                      <CalendarTodayIcon
                        sx={{
                          margin: '2px 0 0 2px',
                          fontSize: '44px',
                          color: '#CCC',
                        }}
                      />
                    ) : (
                      notification.imageUrl && (
                        <img
                          src={notification.imageUrl}
                          alt=""
                          onError={(e) => {
                            (e.target as HTMLImageElement).style.display = 'none';
                          }}
                          style={{
                            width: '50px',
                            height: '50px',
                            borderRadius: '12px',
                          }}
                        />
                      )
                    )}
                  </Box>
                </MenuItem>
              ))}
            </Box>
            <Box
              sx={{
                position: 'sticky',
                bottom: 0,
                backgroundColor: '#FFF',
                textAlign: 'center',
                padding: '10px 0 4px 0',
                borderTop: '1px solid #E9E9E9',
              }}
            >
              <Button
                variant="outlined"
                onClick={handleClearAllNotifications}
                sx={{
                  fontSize: '12px',
                  color: '#888',
                  border: '1px solid #E1E1E1',
                  backgroundColor: '#F6F6F6',
                  width: '120px',
                  height: '20px',
                }}
              >
                Clear All
              </Button>
            </Box>
          </Box>
        )}
      </Menu>
    </Box>
  );
};

export default Notifications;
