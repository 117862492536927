import React, { useState, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Box, Button, TextField, Typography, CircularProgress } from '@mui/material';
import styled from 'styled-components';
import axios from 'axios';
import { API_PREFIX } from '../../constants/urls';
import { SMSMessage } from '../../types/LeadTypes';
import DeleteIcon from '@mui/icons-material/Delete';

// SMS Confirmation modal components
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { formatMessageDate } from '../../utils/formatDateTimeUtils';

const MessagesContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  margin: 0;
  width: 288px;
  @media all and (max-width: 799px){
    width: 100%;
  }
`;

const MessageList = styled.div`
  width: 288px;
  height: 242px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 6px 8px 4px;
  border: 1px solid #E9E9E9;
  border-radius: 14px;
  margin-bottom: 10px;
  box-sizing: border-box;
  @media all and (max-width: 799px) {
    width: 100%;
    height: 199px;
  }
`;

const InputArea = styled(Box)<{ isVisible: boolean }>`
  height: 164px;
  margin-bottom: -6px;
  display: ${props => (props.isVisible ? 'block' : 'none')};
`;

const CustomTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#007FFF',
    },
    '&:hover fieldset': {
      borderColor: '#007FFF',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#007FFF',
    },
  },
  '& .MuiInputLabel-root': {
    color: '#007FFF',
    background: '#FFF',
    padding: '0 6px 0 0'
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: '#007FFF',
  },
});

const StyledMessageButton = styled(Button)`
  && {
    width: 100px;
    margin: 8px 0 0;
    color: #007FFF;
    border: 2px solid #007FFF;
    font-weight: 600;
    float: right;
    &:hover {
      color: white;
      background: #007FFF;
    }
  }
`;

const MessageBubble = styled.div<{ $ownMessage: boolean }>`
  max-width: 87%;
  margin: ${props => (props.$ownMessage ? '0 0 2px 13%' : '0 13% 2px 0')};
  color: ${props => (props.$ownMessage ? '#FFF' : '#000')};
  background-color: ${props => (props.$ownMessage ? '#007FFF' : '#CFE1FF')};
  align-self: ${props => (props.$ownMessage ? 'flex-end' : 'flex-start')};
  padding: 5px 10px 1px;
  border-radius: 12px;
  font-size: 13px;
  line-height: 1.2;
  position: relative;

  &:hover {
    .delete-icon {
      display: ${props => (props.$ownMessage ? 'block' : 'none')};
    }
  }

  .delete-icon {
    display: none;
    position: absolute;
    top: 2px;
    right: 8px;
    color: white;
    width: 16px;
    cursor: pointer;
  }
`;

const MessageText = styled.div`
  margin-bottom: 2px;
`;

const Subtext = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 10px;
`;

const Sender = styled.span`
  text-align: left;
`;

const TimeStamp = styled.span`
  text-align: right;
`;

interface SMSSectionProps {
  leadId: string;
  to: string;
  setMessage: string;
}

const SMSSection: React.FC<SMSSectionProps> = ({ leadId, to, setMessage }) => {
  const [messageList, setMessageList] = useState<SMSMessage[]>([]);
  const [newMessage, setNewMessage] = useState(setMessage);
  const [error, setError] = useState<string | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const messageListRef = useRef<HTMLDivElement>(null);
  const [isSending, setIsSending] = useState(false);

  useEffect(() => {
    setNewMessage(setMessage); // Update newMesasge when setMessage changes
  }, [setMessage]);

  const validatePhoneNumber = (phone: string) => {
    return phone.startsWith('04') || phone.startsWith('+614');
  };

  // Function to fetch SMS messages from the new endpoint
  const fetchMessages = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_PREFIX}/get-sms`, {
        headers: { Authorization: `Bearer ${token}` },
        params: { phone: to },
      });
  
      // Check if the response contains the array of messages
      if (response.data && Array.isArray(response.data.messages)) {
        setMessageList(response.data.messages);
      } else if (Array.isArray(response.data)) {
        // Fallback for cases where data itself is an array
        setMessageList(response.data);
      } else {
        console.error('Expected array but got:', response.data);
        setMessageList([]);
      }
    } catch (err) {
      console.error('Error fetching messages:', err);
      setMessageList([]); // Fallback to an empty array on error
    }
  };

  useEffect(() => {
    fetchMessages();
  }, [leadId]);

  useEffect(() => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  }, [messageList]);

  const sendSMS = async (to: string, message: string, sender: string) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        `${API_PREFIX}/send-sms`,
        { leadId, to, message, sender },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const handleSendSMS = async () => {
    setDialogOpen(true);
  };

  const confirmSendSMS = async () => {
    setDialogOpen(false);
    setIsSending(true);
  
    if (!newMessage.trim()) {
      setIsSending(false); // Stop the loading indicator
      return;
    }
  
    const tempMessage: SMSMessage = {
      id: uuidv4(), // Use a unique temporary ID
      to,
      text: newMessage,
      sender: 'CBO',
      postedAt: new Date().toISOString(),
      status: 'sending',
    };
  
    try {
      // Await the `sendSMS` function
      const sentMessage = await sendSMS(to, newMessage, 'CBO');
     
      // Replace the temporary message with the response message
      setMessageList(prev =>
        prev.map(msg =>
          msg.id === tempMessage.id ? { ...msg, id: sentMessage.id, status: 'sent' } : msg
        )
      );
    } catch (error) {
      console.error('Error sending SMS:', error);
  
      // Mark the message as failed
      setMessageList(prev =>
        prev.map(msg =>
          msg.id === tempMessage.id ? { ...msg, status: 'failed' } : msg
        )
      );
    } finally {
      setNewMessage(''); // Clear the input field
      setIsSending(false); // Stop the loading indicator
      fetchMessages(); // Refresh messages
    }
  };
  

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  return (
    <MessagesContainer>
      {error ? (
        <MessageList ref={messageListRef}>
          <Box mt={1}>
            <Typography color="error" variant="body2">
              {error}
            </Typography>
          </Box>
        </MessageList>
      ) : (
        <>
          <MessageList ref={messageListRef}>
            {messageList.map(msg => (
              <MessageBubble key={msg.id} $ownMessage={msg.sender === 'CBO'}>
                <MessageText>{msg.text}</MessageText>
                <Subtext>
                  <Sender>{msg.sender !== 'CBO' && msg.sender}</Sender>
                  <TimeStamp>
                    {formatMessageDate(msg.postedAt)}
                  </TimeStamp>
                </Subtext>
              </MessageBubble>
            ))}
            {isSending && <CircularProgress size={20} style={{ color: '#007FFF', float: 'right', margin: '5px 5px' }} />}
          </MessageList>
          <InputArea isVisible={!error}>
            <CustomTextField
              fullWidth
              variant="outlined"
              label="Send a message"
              value={newMessage}
              onChange={e => setNewMessage(e.target.value)}
              onKeyDown={e => e.key === 'Enter' && handleSendSMS()}
              autoFocus
              multiline
              rows={4} // Set an initial height of 5 rows
              InputProps={{
                style: { resize: 'vertical', fontSize: '15px', lineHeight: '18px' }, // Allow vertical resizing if desired
              }}
            />
            <StyledMessageButton onClick={handleSendSMS}>Send SMS</StyledMessageButton>
          </InputArea>
          {/* Confirmation Dialog */}
          <Dialog
            open={dialogOpen}
            onClose={handleDialogClose}
            aria-labelledby="sms-confirmation-dialog-title"
            aria-describedby="sms-confirmation-dialog-description"
          >
            <DialogTitle id="sms-confirmation-dialog-title">{"Confirm SMS"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="sms-confirmation-dialog-description">
                You are about to send the following SMS to: <span style={{color: 'rgba(0,0,0,0.87)'}}>{to}</span>
                <Box mt={2} p={1} bgcolor="#f0f0f0" borderRadius={1}>
                  <Typography variant="body2">{newMessage}</Typography>
                </Box>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose}>Cancel</Button>
              <Button onClick={confirmSendSMS} autoFocus>
                Send SMS
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </MessagesContainer>
  );
};

export default SMSSection;
