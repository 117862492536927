import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  CircularProgress,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
} from '@mui/material';
import styled from 'styled-components';
import { ADMIN_USERS } from '../../constants/adminUsers';


// Styled dialog for consistent appearance
const StyledDialog = styled(Dialog)`
  && {
    display: block;
    max-width: 600px;
    margin: 0 auto;
  }
`;

// Interface for modal props
interface PDFNotesModalProps {
  open: boolean;
  handleClose: () => void;
  activeUserId: string | null;
  leadId: string;
  currentNotes: string;
  onNotesUpdate: (newNotes: string) => void;
  generatePDF: (notes: string, salesPersonId: string) => void;
}

// PDFNoteModal component definition
const PDFNoteModal: React.FC<PDFNotesModalProps> = ({
  open,
  handleClose,
  activeUserId,
  leadId,
  currentNotes,
  onNotesUpdate,
  generatePDF,
}) => {
  // State for managing notes, loading state, and character count
  const [notes, setNotes] = useState(currentNotes);
  const [loading, setLoading] = useState(false);
  const [charCount, setCharCount] = useState(0);
  const [selectedSalesperson, setSelectedSalesperson] = useState(
    ADMIN_USERS.find((user) => user.id === activeUserId) || ADMIN_USERS[0]
  );

  // Character limit for notes
  const charLimit = 440;

  // Update character count whenever notes change
  useEffect(() => {
    setCharCount(notes.length);
  }, [notes]);

  // Handle PDF generation and note updates
  const handleSave = async () => {
    try {
      setLoading(true);
      onNotesUpdate(notes);
      generatePDF(notes, selectedSalesperson.id);
    } catch (error) {
      console.error('Error generating PDF:', error);
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  // Render modal with character counter and warning
  return (
    <StyledDialog open={open} onClose={handleClose}>
      <DialogTitle>Edit PDF Notes</DialogTitle>
      <DialogContent style={{ paddingTop: '10px' }}>
        <TextField
          fullWidth
          multiline
          rows={4}
          variant="outlined"
          label="PDF Notes"
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          style={{ width: '600px', maxWidth: '100%' }}
        />
        <Typography
          variant="body2"
          color={charCount > charLimit ? 'error' : 'textSecondary'}
          style={{ marginTop: '4px', fontSize: '12px' }}
        >
          {charCount}/{charLimit}
        </Typography>
        {charCount > charLimit && (
          <Typography variant="caption" color="error">
            Character limit exceeded. Please shorten your notes.
          </Typography>
        )}
      </DialogContent>
      <DialogActions style={{ display: 'flex', justifyContent: 'space-between', padding: '10px 25px 20px' }}>
        <FormControl style={{ width: '200px' }}>
          <InputLabel style={{ padding: '0 5px', background: 'white' }}>Contact Details</InputLabel>
          <Select
            labelId="salesperson-select-label"
            value={selectedSalesperson.id}
            onChange={(e) =>
              setSelectedSalesperson(
                ADMIN_USERS.find((user) => user.id === e.target.value) || ADMIN_USERS[0]
              )
            }
            style={{ lineHeight: '30px' }}
          >
            {ADMIN_USERS.map((user) => (
              <MenuItem key={user.id} value={user.id} >
                {user.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box>
          {loading && <CircularProgress size={24} />}
          <Button onClick={handleClose} color="secondary" disabled={loading} style={{ textAlign: 'center', width: '115px' }}>
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary" disabled={loading || charCount > charLimit} style={{ textAlign: 'center', width: '115px' }}>
            {loading ? 'Generating...' : 'Generate PDF'}
          </Button>
        </Box>
      </DialogActions>
    </StyledDialog>
  );
};

export default PDFNoteModal;
