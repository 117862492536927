import React, { useState, useEffect } from 'react';
import { IconButton, Dialog, DialogTitle, DialogContent, List, ListItem, ListItemText, CircularProgress, Tooltip } from '@mui/material';
import AssistantIcon from '@mui/icons-material/Assistant';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { API_PREFIX } from '../../constants/urls';

interface ChatLogProps {
  chatSessionId?: string;
}

const ChatLog: React.FC<ChatLogProps> = ({ chatSessionId }) => {
  const [open, setOpen] = useState(false);
  const [messages, setMessages] = useState<{ text: string; sender: string; timestamp: string }[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open && chatSessionId) {
      fetchChatMessages();
    }
  }, [open]);

  const fetchChatMessages = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_PREFIX}/chat-sessions/${chatSessionId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setMessages(response.data.messages || []);
    } catch (error) {
      console.error('Error fetching chat messages:', error);
    }
    setLoading(false);
  };

   // Only show the icon if chatSessionId exists
  if (!chatSessionId) return null;

  return (
    <>
      <Tooltip title="View Chat Log" placement="top">
        <IconButton onClick={() => setOpen(true)} color="primary" style={{ top: '2px', width: '26px', height: '26px', borderRadius: '8px' }}>
          <AssistantIcon style={{ fontSize: '20px', color: '#B2B2B2', marginTop: '1px' }} />
        </IconButton>
      </Tooltip>

      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
        <DialogTitle>
          Virtual Assistant - Chat Log
          <IconButton onClick={() => setOpen(false)} style={{ position: 'absolute', right: '15px', top: '15px', width: '32px', height: '32px' }}>
            <CloseIcon style={{ color: '#777777', width: '26px' }} />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {loading ? (
            <CircularProgress style={{ margin: '10px auto'}} />
          ) : messages.length > 0 ? (
            <List>
              {messages.map((msg, index) => (
                <ListItem key={index} style={{ display: 'flex', justifyContent: msg.sender === 'user' ? 'flex-start' : 'flex-end', padding: '0' }}>
                  <ListItemText
                    primary={msg.text}
                    primaryTypographyProps={{ style: { fontSize: '0.9rem', marginBottom: '3px' } }}
                    secondary={new Date(msg.timestamp).toLocaleString()}
                    secondaryTypographyProps={{ style: { fontSize: '0.75rem' } }}
                    style={{
                      background: msg.sender === 'user' ? '#e3f2fd' : '#def196',
                      padding: '8px 12px',
                      borderRadius: '10px',
                      maxWidth: '75%',
                      wordBreak: 'break-word',
                    }}
                  />
                </ListItem>
              ))}
            </List>
          ) : (
            <p>No messages found.</p>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ChatLog;
