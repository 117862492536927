import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { format } from 'date-fns';

// Extend dayjs with the custom parse format plugin
dayjs.extend(customParseFormat);

// Utility function to format the date
export const formatDate = (date: Date) => {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  const isToday = date.toDateString() === today.toDateString();
  const isYesterday = date.toDateString() === yesterday.toDateString();

  if (isToday) {
    return 'Today';
  } else if (isYesterday) {
    return 'Yesterday';
  } else {
    // Format date as "dd/mm/yyyy"
    return date.toLocaleDateString("en-AU");
  }
};

export const formatTime = (date?: Date) => {
  // Format time as "hh:mm AM/PM"
  return date?.toLocaleTimeString("en-AU", { hour: '2-digit', minute: '2-digit', hour12: true });
};

export function reformatDate(dateString: string) {
    return dayjs(dateString, 'YYYY-MM-DD').format('DD/MM/YYYY');
};

export const formatHumanReadableDate = (date: string): string => {
  const parsedDate = new Date(date);

  // Check if the parsed date is valid
  if (isNaN(parsedDate.getTime())) {
    // Return a fallback message or empty string if the date is invalid
    return 'Invalid date';
  }

  // If the date is valid, format it
  return format(parsedDate, "MMM do");
};

export const formatMessageDate = (dateString: string) => {
  const date = new Date(dateString);
  const now = new Date();
  const oneDayMs = 24 * 60 * 60 * 1000;
  const diff = now.getTime() - date.getTime();

  const timeOptions: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit' };
  const dateOptions: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric', ...timeOptions };

  let formattedDate: string;

  if (diff < oneDayMs && date.toDateString() === now.toDateString()) {
    formattedDate = date.toLocaleTimeString([], timeOptions);
  } else if (diff < oneDayMs * 2) {
    formattedDate = `Yesterday at ${date.toLocaleTimeString([], timeOptions)}`;
  } else {
    formattedDate = date.toLocaleDateString('en-AU', dateOptions);
  }

  return formattedDate.replace('AM', 'am').replace('PM', 'pm');
};
